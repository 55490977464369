
import template from './overview.html';

class GrapeOverviewViewModel
{
	constructor(page)
	{
		this.loaded = ko.observable(false);
		this.page = page;

		this.sysinfo = {
			loaded: ko.observable(false),
			name: ko.observable(),
			url: ko.observable(),
			patches: ko.observableArray(),
			server_time: ko.observable(),
			client_time_delta: ko.observable()
		};

		this.dbinfo = {
			loaded: ko.observable(false),
			current_database: ko.observable(),
			pg_listening_channels: ko.observable(),
			version: ko.observable(),
			database_size: ko.observable(),
			numbackends: ko.observable(),
			pg_backend_pid: ko.observable(),
			table_count: ko.observable()
		};

		this.userinfo = {
			loaded: ko.observable(false),
			user_count: ko.observable(),
			active_user_count: ko.observable(),
			session_count: ko.observable()
		};

		this.os_string = ko.observable();
		this.cpu_info = ko.observable();
		this.cpu_temp = ko.observable();
		this.mem = ko.observable();

		this.process_list = ko.observableArray();
		this.plugin_list = ko.observableArray();

		
		this.ui_modules = ko.observableArray(window.Grape.modules);
	}

	async stop_process (name, idx)
	{
		let response = await fetch('/api/worker/slot/stop', {
			method: 'POST',
			headers: {'Content-type': 'application/json'},
			body: JSON.stringify({
				cmd: 'stop',
				worker_name: name,
				slot_idx: idx
			})
		});
		let json = await response.json();
		await this.page.updateData();
	}

	copy_db_info ()
	{
		let db_info = this.dbinfo();

		let formatted_text = 
			`Database name: ${db_info.current_database}\n` +
			`PostgreSQL Version: ${db_info.version}\n` +
			`Database Size: ${db_info.database_size}\n` +
			`Tables: ${db_info.table_count}\n` +
			`Listening Channels: ${db_info.pg_listening_channels.join(', ')}\n` +
			`Connection count: ${db_info.numbackends}\n` +
			`Backend PID: ${db_info.pg_backend_pid}`;

		navigator.clipboard.writeText(formatted_text);
		Grape.alerts.alert({type: 'success', title: 'Success', message: 'Copied to clipboard!'});
	}
	
	copy_user_info ()
	{
		let user_info = this.userinfo();

		let formatted_text = 
			`Application User Count: ${user_info.app_user_count}\n` +
			`Active Application User Count: ${user_info.active_app_user_count}\n` +
			`Session Count: ${user_info.session_count}`;

		navigator.clipboard.writeText(formatted_text);
		Grape.alerts.alert({type: 'success', title: 'Success', message: 'Copied to clipboard!'});
	}
	
	copy_service_info ()
	{
		let service_info = {
			'server time': this.server_time(),
			'latency': this.client_time_delta(),
			'service name': this.svc_name(),
			'url': this.svc_url(),
			'database patches': this.svc_patches(),
			'ui modules': this.svc_loaded()
		};

		let formatted_text = 
			`Server Time: ${service_info['server time']}\n` +
			`Latency: ${service_info.latency}\n` +
			`Service Name: ${service_info['service name']}\n` +
			`URL: ${service_info.url}\n` +
			`Database Patches: ${JSON.stringify(service_info['database patches'], null, 2)}\n` +
			`UI Modules Loaded: ${service_info['ui modules']}`;

		navigator.clipboard.writeText(formatted_text);
		Grape.alerts.alert({type: 'success', title: 'Success', message: 'Copied to clipboard!'});
	}
	
	copy_process_info ()
	{
		let process_info = {
			'process list': this.process_list(),
			'plugin list': this.plugin_list()
		};

		let formatted_text = 
			`Process List: ${JSON.stringify(process_info['process list'], null, 2)}\n` +
			`Plugin List: ${JSON.stringify(process_info['plugin list'], null, 2)}`;
		
		navigator.clipboard.writeText(formatted_text);
		Grape.alerts.alert({type: 'success', title: 'Success', message: 'Copied to clipboard!'});
	}

	back_to_top ()
	{
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}
}

class GrapeOverviewPage
{
	constructor(bindings)
	{
		this.viewModel = new GrapeOverviewViewModel(this);
		this.bindings = bindings;
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Dashboard - Overview';
	}

	async updateData (v,e)
	{
		await Promise.all([
			this.getSystemInfo(),
			this.getProcessInfo(),
			this.getSystemStatus()
		]);
	}

	async getSystemInfo()
	{
		let d = await Grape.fetches.getJSON('/api/sys/info', {client_time: (new Date()).toISOString(), sections: ['patches']});

		if (d != undefined)
		{
			this.viewModel.sysinfo.name(d.public_settings.service_name);
			this.viewModel.sysinfo.url(d.public_settings.url);
			const patches = [];
			for (let p of d.patches)
			{
				const major = parseInt(p.version / 1000000);
				const minor = parseInt((p.version - (major * 1000000)) / 1000);
				const patch = parseInt(p.version - (major * 1000000) - (minor * 1000));
				const version = `v${major}.${minor}.${patch}`;
				patches.push({system: p.system, version: version});
			}
			this.viewModel.sysinfo.patches(patches);

			this.viewModel.sysinfo.server_time(new Date(d.server_time));
			this.viewModel.sysinfo.client_time_delta(d.client_time_delta);
			
			this.viewModel.sysinfo.loaded(true);
		}
		else
			console.error("Could not load sysinfo!");
	}

	async getSystemStatus()
	{
		let result = await Grape.fetches.getJSON('/api/sys/status');

		// TODO
		if (result.status != 'OK')
		{
			this.viewModel.dbinfo.loaded('Error');
			this.viewModel.userinfo.loaded('Error');
			return;
		}
		
		let d = result['@platsoft/api-backend'];
		if (d != undefined)
		{
			this.viewModel.dbinfo.current_database(d.current_database);
			this.viewModel.dbinfo.pg_listening_channels(d.pg_listening_channels);
			this.viewModel.dbinfo.version(d.version);
			this.viewModel.dbinfo.database_size(d.database_size);
			this.viewModel.dbinfo.numbackends(d.numbackends);
			this.viewModel.dbinfo.pg_backend_pid(d.pg_backend_pid);
			this.viewModel.dbinfo.table_count(d.table_count);
			this.viewModel.dbinfo.loaded(true);
		}
		else
			console.error("Could not load dbinfo!");


		d = result['@platsoft/users'];
		if (d != undefined)
		{
			this.viewModel.userinfo.user_count(d.user_count);
			this.viewModel.userinfo.active_user_count(d.active_user_count);
			this.viewModel.userinfo.session_count(d.session_count);
			this.viewModel.userinfo.loaded(true);
		}
		else
			console.error("Could not load userinfo!")
	}

	async getProcessInfo()
	{
		let d = await Grape.fetches.getJSON('/api/sys/info/process');

		// TODO
		if (d.status != 'OK')
			return;

		let proclist = [];

		for (let p of d.manager.process_list)
		{	
			if (p['mem'] == undefined)
				continue;

			let rss = parseInt(p.mem.rss);
			p.mem_str = '' + parseInt(rss / 1024 / 1024) + 'MB';
			proclist.push(p);
		}
		this.viewModel.process_list(proclist);
		this.viewModel.plugin_list(d.manager.plugin_list);
		
		this.viewModel.loaded(true);
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/]ui/overview',
	page_class: GrapeOverviewPage,
	template: template,
	name: 'overview',
	title: 'Overview',
	page_id: 'ps_dashboard.overview',
	icon: 'fa-regular fa-info-circle',
	idx: 0
};

class FieldModel 
{
	constructor({field_uuid, schema, table, name, display_name, description, idx, options})
	{
		this.field_uuid = ko_helper.safe_observable(field_uuid);
		this.schema = ko_helper.safe_observable(schema);
		this.table = ko_helper.safe_observable(table);
		this.name = ko_helper.safe_observable(name);
		this.display_name = ko_helper.safe_observable(display_name);
		this.description = ko_helper.safe_observable(description);
		this.idx = ko_helper.safe_observable(idx);
		this.options = ko.observable();

		if (typeof options === 'object')
			this.options(JSON.stringify(this.options()));
		else
			this.options(options);
	}

	async fetch()
	{
		// TODO
	}

	async save()
	{
		if (!this.name())
			Grape.alerts.alert({type: 'warning', message: 'Field name cannot be empty!'});
		else
		{
			let payload = this.serialize();
			if (typeof payload.options === 'string')
			{
				try {
					payload.options = JSON.parse(payload.options);
				} catch (err) {
					return {status: 'ERROR', code: 'Invalid Data', message:'Options is not valid JSON'};
				}
			}
			return Grape.fetches.postJSON('/api/custom-fields/field/upsert', payload);
		}
	}

	async delete()
	{
		return Grape.fetches.deleteJSON('/api/custom-fields/field/delete', {field_uuid: this.field_uuid()});
	}

	serialize()
	{
		return {
			field_uuid: this.field_uuid(),
			schema: this.schema(),
			table: this.table(),
			name: this.name(),
			display_name: this.display_name(),
			description: this.description(),
			idx: this.idx(),
			options: this.options(),
		};
	}

}

export default FieldModel;

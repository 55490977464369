import template from './edit-subscription.html';

class EditSubscriptionDialogViewModel
{
	constructor(dialog)
	{
		this.dialog = dialog;

		//Render fields params
		this.inputs = ko.observable({});
		this.fields = ko.observableArray([]);
		this.initialValue = ko.observable();

		//Events and actions
		this.event_namespaces = ko.observableArray();
		this.selected_namespace = ko.observable();
		this.event_objects = ko.observableArray();
		this.selected_object = ko.observable();
		this.event_names = ko.observableArray();
		this.selected_name = ko.observable();
		this.actions = ko.observableArray();
		this.selected_action_qname = ko.observable();
		this.raw_event_data = ko.observableArray();
		this.raw_action_data = ko.observableArray();

		//Subscriptions
		this.subscription_uuid = ko.observable('');
		this.active = ko.observable(true);
		this.idx = ko.observable();
		this.event_data_match = ko.observable();

		this.selected_namespace.subscribe(() => this.dialog.get_event_object_type() );
		this.selected_object.subscribe(() => this.dialog.get_event_names() );

		this.selected_action_values = ko.computed(() => {
			let actions = this.actions();
			let selected_values = actions.find(x => x.action_qname === this.selected_action_qname());

			if (typeof selected_values != 'undefined')
				this.fields(selected_values.ui_fields);

			return selected_values
		});

		this.json_map_definitions = ko.observableArray();
		this.json_map_definition_id = ko.observable();
	}

	async btn_save_click ()
	{
		try
		{
			let config_data = {};

			if (!this.selected_namespace())
				throw new Error('No event chosen');
			else if (!this.selected_action_qname())
				throw new Error('No action chosen');
			else
			{
				for (let [name, $input] of Object.entries(this.inputs()))
					config_data[name] = ($input)();



				let payload = {
					subscription_uuid: this.subscription_uuid() != '' ? this.subscription_uuid() : null,
					event_qname: `${this.selected_namespace()}.${this.selected_object()}.${this.selected_name()}`,
					action_qname: this.selected_action_qname(),
					active: this.active(),
					idx: this.idx(),
					json_map_definition_id: this.json_map_definition_id()||null,
					config_data: config_data,
				};

				const event_data_match = this.event_data_match();
				try {
					if (event_data_match)
						payload.event_data_match = JSON.parse(event_data_match);
				} catch(err) {
					throw new Error(`Event data match must be valid JSON`);
				};

				let result = await Grape.fetches.postJSON('/api/events/subscription/', payload);

				if (result.status !== "OK")
					throw new Error(result.message || 'Unable to save data');
				else
					await Grape.alerts.alert({message: 'Saved'});
					this.dialog.close(true);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	btn_close_click ()
	{
		this.dialog.close(false);
	}
}

class EditSubscriptionDialogClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new EditSubscriptionDialogViewModel(this);
	}

	async init ()
	{
		document.title = 'Dashboard - Events'
		this.load(this.bindings?.subscription || null);
	}

	get name() { return 'EditSubscriptionDialog'; }

	async get_actions ()
	{
		let response = await Grape.fetches.getJSON('/api/record', {schema: 'events', table: 'v_actions'});
		this.viewModel.raw_action_data(response.records);

		let actions = this.viewModel.raw_action_data().map(x => {
			return {
				action_qname: `${x.namespace}.${x.name}`,
				ui_fields: x.ui_fields
			}
		});
		this.viewModel.actions(actions);
	}

	async load (subscription)
	{
		await this.get_event_namespace();
		await this.get_actions();
		await this.get_json_map_definitions();

		if (subscription)
		{
			this.viewModel.subscription_uuid(subscription.subscription_uuid);
			this.viewModel.initialValue(subscription.config_data);
			this.viewModel.selected_action_qname(`${subscription.action_namespace}.${subscription.action_name}`);
			this.viewModel.active(subscription.active);
			this.viewModel.idx(subscription.idx);
			this.viewModel.event_data_match(JSON.stringify(subscription.event_data_match));
			this.viewModel.json_map_definition_id(subscription.json_map_definition_id);
			this.viewModel.selected_namespace(subscription.event_namespace);
			this.viewModel.selected_object(subscription.event_object_type);
			this.viewModel.selected_name(subscription.event_name);
		}
	}

	async get_event_namespace ()
	{
		this.viewModel.raw_event_data(await Grape.cache.fetch('EventNames'));
		let namespace = this.viewModel.raw_event_data().map(x => {
			return x.namespace
		});

		this.viewModel.event_namespaces(namespace);
	}

	get_event_object_type ()
	{
		let object_type = this.viewModel.raw_event_data().filter(
			x => x.namespace === this.viewModel.selected_namespace()
		).map(
			x => { return x.object_type}
		);
		this.viewModel.event_objects(object_type);
	}

	get_event_names ()
	{
		let name = this.viewModel.raw_event_data().filter(
			x => x.namespace === this.viewModel.selected_namespace() && x.object_type === this.viewModel.selected_object()
		).map(
			x => { return x.name}
		);
		this.viewModel.event_names(name);
	}

	async get_json_map_definitions(){
		let mapDefinitions = await Grape.tables.select({
			schema: 'json_mapper',
			table: 'map_definition'
		});
		this.viewModel.json_map_definitions(mapDefinitions.records||[]);
	}
}

export default {
	name: 'EditSubscriptionDialog',
	provider: 'ps',
	dialog_class: EditSubscriptionDialogClass,
	template: template
};


Grape.registry.addRegister('PSServerDashboardPages');

import caches from './caches/index.js';
import components from './components/index.js';
import dialogs from './dialogs/index.js';
import pages from './pages/index.js';
import dashboard_pages from './pages/dashboard_pages/index.js';
import PackageJSON from '../package.json';

import UserSettingPlugin from './lib/UserSettings.js';


document.addEventListener('DOMContentLoaded', () => {

	window.Grape.modules.push(PackageJSON);

	for (let page of dashboard_pages)
	{
		Grape.pages.add_page(page);
		Grape.registry.addItem(
			'PSServerDashboardPages',
			`${page.title}`,
			{
				title: page.title,
				icon: page.icon,
				page_id: page.page_id || page.route || null,
				idx: page.idx
			});
	}

	/*Grape.registry.addItem(
		'PSServerDashboardPages',
		`Custom Fields`,
		{
			title: 'Custom Fields',
			icon: 'fa-solid fa-pen-field',
			page_id: '[/]custom-fields/field-tables'
		});*/

	Grape.plugins.register(UserSettingPlugin);

	window.Grape.cache.registerCaches(caches);
	window.Grape.component.registerComponents(components);
	window.Grape.dialog.registerDialogs(dialogs);
	window.Grape.pages.registerPages(pages);
});

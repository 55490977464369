class JsonMapField
{
	constructor(data)
	{
		this.map_field_id = ko.observable();
		this.map_definition_id = ko.observable();
		this.namespace = ko.observable();
		this.name = ko.observable()
		this.field_name = ko.observable();
		this.idx = ko.observable();
		this.source_selector = ko.observable();
		this.target_path = ko.observableArray();
		this.target_path_text = ko.pureComputed({
			read: function(){
				return this.target_path().join('.');
			},
			write: function(value){
				if (typeof value === 'string')
					this.target_path(value.split('.'));
			},
			owner: this
		});
		this.transform_type = ko.observable();
		this.transform_options = ko.observable();

		if (data)
			this.set(data);
	}

	async set(data){
		if (data.hasOwnProperty('map_field_id'))
			this.map_field_id(data.map_field_id);

		if (data.hasOwnProperty('map_definition_id'))
			this.map_definition_id(data.map_definition_id);
		if (data.hasOwnProperty('namespace'))
			this.namespace(data.namespace);
		if (data.hasOwnProperty('name'))
			this.name(data.name);

		if (data.hasOwnProperty('field_name'))
			this.field_name(data.field_name);

		if (data.hasOwnProperty('idx'))
			this.idx(data.idx);

		if (data.hasOwnProperty('source_selector'))
			this.source_selector(data.source_selector);

		if (data.hasOwnProperty('target_path'))
			this.target_path(data.target_path);

		if (data.hasOwnProperty('transform_type'))
			this.transform_type(data.transform_type);

		if (data.hasOwnProperty('transform_options')){
			if (data.transform_options)
				this.transform_options(JSON.stringify(data.transform_options));
		}
	}

	async save()
	{
		let payload = this.serialize();

		return Grape.fetches.postJSON('/api/json-map/field', payload);
	}

	async delete()
	{
		return Grape.fetches.deleteJSON('/api/json-map/field', {map_field_id:this.map_field_id()});
	}

	serialize()
	{
		let obj = {
			// ko.toJS?
			map_field_id: this.map_field_id(),
			map_definition_id: this.map_definition_id(),
			namespace: this.namespace(),
			name: this.name(),
			field_name: this.field_name(),
			idx: this.idx(),
			source_selector: this.source_selector(),
			target_path: this.target_path(),
			transform_type: this.transform_type()
		};

		let transform_options = (this.transform_options()||'').trim();
		if (transform_options)
			obj.transform_options = JSON.parse(transform_options)

		return obj
	}
}

export default JsonMapField;

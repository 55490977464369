import template from './view_session.html';

class ViewSessionViewModel
{
	constructor(page)
	{
		this.page = page;
		this.session_info = ko_helper.safe_observable(this.page.bindings.session);
	}

	async btn_back_click()
	{
		this.page.close();
	}
}

class ViewSessionDialog
{
	constructor(bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ViewSessionViewModel(this);
		this.viewModel.session_info(bindings.session);
	}
}

export default {
	name: 'ViewSession',
	dialog_class: ViewSessionDialog,
	template: template,
	provider: "ps"
}

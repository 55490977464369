
import template from './AddEditMapDefinition.html';
import MapDefinitionModel from './../../lib/models/json_mapper/JsonMapDefinitionModel.js'

class AddEditMapDefinitionVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.edit = this.dialog.bindings.edit;
		this.mapDefinition = ko.observable(new MapDefinitionModel(this.dialog.bindings.mapDefinition));
	}

	close()
	{
		this.dialog.close();
	}

	async save()
	{
		let res;
		try {
			res = await this.mapDefinition().save();
		} catch(err) {
			Grape.alerts.apiError(err);
		}

		if (res)
		{
			if (res?.status !== 'OK')
			{
				Grape.alerts.apiError(res);
				return;
			}

			Grape.alerts.alert({type:'success', title:'Success', message:'Successfully Saved.'});
			this.dialog.close(res);
		}
	}
}

class AddEditMapDefinitionDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings || {};
		this.viewModel = new AddEditMapDefinitionVM(this); // Name of the ViewModel defined above
		this.name = 'AddEditMapDefinition'; // Unique dialog name
	}
}

export default {
	name: 'AddEditMapDefinition',
	dialog_class: AddEditMapDefinitionDialog,
	provider: 'ps',
	template: template
}

import template from './overview.html';

class ProfileOverviewPageVM
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;

		this.user = ko.observable(this.page.bindings.data || {});
		this.roles_str = ko.observable(this.user()?.user_roles ? this.user().user_roles.join(', ') : {});
		this.email = ko.observable(this.user().email || '-');
		this.pg_role = ko.observableArray([]);
		this.theme_list = ko.observableArray([]);
		this.selected_theme_mode = ko.observable();
		this.selected_theme= ko.observable();

		this.selected_theme_mode.subscribe((newVal) => {			
			if (newVal)
				if (newVal !== Grape.themes.getMode())
					Grape.themes.setUserTheme(this.selected_theme(), newVal);
		});

		this.selected_theme.subscribe((newVal) => {			
			if (newVal)
				if (newVal !== Grape.themes.getTheme())
					Grape.themes.setUserTheme(newVal, this.selected_theme_mode());
		});
	}	

	item_click (theme)
	{
		this.selected_theme(theme);
	}
}

class ProfileOverviewPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ProfileOverviewPageVM(this);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Profile Overview';
		this.viewModel.selected_theme(Grape.themes.getTheme() || 'default');
		this.viewModel.selected_theme_mode(Grape.themes.getMode() || 'light');
	
		let theme_list = await Grape.themes.getThemes();
		this.viewModel.theme_list(theme_list);
	}

	async updateData ()
	{
		try 
		{
			let result = await fetch('/api/user/-1');
			let data = await result.json();

			if (result.ok)
			{
				this.viewModel.user(data.user);
				this.viewModel.roles_str(data.user.user_roles.join(', '));
				this.viewModel.email(data.user.email);
			}
			
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}	
	}
	
	new_password ()
	{
		Grape.dialog.open('NewPassword');
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/profile/]overview',
	page_id: 'overview',
	page_class: ProfileOverviewPage,
	template: template
};
import EditSubscription from './edit_subscription/edit-subscription.js'
import UserEdit from './users/user_edit.js';
import DataInput from './data_input/data_input_dialog.js';
import ViewSession from './view_session/view_session.js';
import EditSetting from './edit_setting/setting_edit.js';
import AddEditFieldsTable from './custom-fields/AddEditFieldsTable.js';
import AddEditTableField from './custom-fields/AddEditTableField.js';
import AddEditMapDefinition from './json_mapper/AddEditMapDefinition.js';
import AddEditMapField from './json_mapper/AddEditMapField.js';
import MapDefinitionTest from './json_mapper/MapDefinitionTest.js';
import AddEditLookup from './lookups/AddEditLookup.js';
import AddEditLookupValue from './lookups/AddEditLookupValue.js'

export default [
    EditSubscription,
	UserEdit,
	DataInput,
	ViewSession,
	EditSetting,
	AddEditFieldsTable,
	AddEditTableField,
	AddEditMapDefinition,
	AddEditMapField,
	MapDefinitionTest,
	AddEditLookup,
	AddEditLookupValue
];

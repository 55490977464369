
import template from './AddEditTableField.html';
import FieldModel from '../../lib/models/FieldModel.js'

class AddEditTableFieldVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.edit = this.dialog.bindings.edit;
		this.tableField = ko.observable();
		this.fetchFieldsTables().then(() => {
			this.tableField(new FieldModel(this.dialog.bindings.tableField));
		});

		this.fieldsTablesMap = ko.observable({});

		this.schemaOptions = ko.computed(() => {
			return Object.keys(this.fieldsTablesMap());
		});

		this.tableOptions = ko.computed(() => {
			if (!this.tableField())
				return [];
			return this.fieldsTablesMap()[this.tableField().schema()];
		});
	}

	close()
	{
		this.dialog.close();
	}

	async save()
	{
		let res = await this.tableField().save();
		if (res)
		{
			if (res.status !== 'OK')
			{
				Grape.alerts.apiError(res);
				return;
			}

			Grape.alerts.alert({type:'success', title:'Success', message:'Successfully Saved.'});
			this.dialog.close(res);
		}
	}

	async fetchFieldsTables()
	{
		let fieldsTables = await Grape.tables.select({schema: 'fields', table: 'fields_table'});
		if (!fieldsTables?.records)
			return;

		let fieldsTablesMap = {};
		for (let fieldsTable of fieldsTables.records)
		{
			if (!fieldsTablesMap[fieldsTable.schema])
				fieldsTablesMap[fieldsTable.schema] = [];

			fieldsTablesMap[fieldsTable.schema].push(fieldsTable.table);
		};
		this.fieldsTablesMap(fieldsTablesMap);
	}
}

class AddEditTableFieldDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings || {};
		this.viewModel = new AddEditTableFieldVM(this); // Name of the ViewModel defined above
		this.name = 'AddEditTableField'; // Unique dialog name
	}
}

export default {
	name: 'AddEditTableField',
	dialog_class: AddEditTableFieldDialog,
	provider: 'ps',
	template: template
}

import template from './AddEditLookup.html';
import LookupModel from '../../lib/models/lookup/LookupModel.js'

class AddEditLookupVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.edit = this.dialog.bindings.edit;
		this.lookup = ko.observable(new LookupModel(this.dialog.bindings.lookup));
	}

	close()
	{
		this.dialog.close();
	}

	async save()
	{
		let res = await this.lookup().save();
		if (res)
		{
			if (res.status !== 'OK')
			{
				Grape.alerts.apiError(res);
				return;
			}

			Grape.alerts.alert({type:'success', title:'Success', message:'Successfully Saved.'});
			this.dialog.close(res);
		}
	}
}

class AddEditLookupDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings || {};
		this.viewModel = new AddEditLookupVM(this); // Name of the ViewModel defined above
		this.name = 'AddEditLookup'; // Unique dialog name
	}
}

export default {
	name: 'AddEditLookup',
	dialog_class: AddEditLookupDialog,
	provider: 'ps',
	template: template
}
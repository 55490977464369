import template from './data_input_dialog.html';

class DataInputVM
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.title = ko.observable();
		this.message = ko.observable();
		this.btn_text = ko.observable();
		this.header_icon = ko.observable();
		this.dialog_width = ko.observable();

		//Render fields params
		this.inputs = ko.observable({});
		this.fields = ko.observableArray([]);
		this.initialValue = ko.observable();
	}

	btn_close_click ()
	{
		this.dialog.close(null); 
	}

	async btn_save_click ()
	{
		//let obj = Grape.retrieve_fields('#div_data_input_fields', this.dialog.fields);
		//obj = _.extend(this.dialog.bindings.data || {}, obj);
		let config_data = {};
		
		if (this.dialog.bindings.data.network_id)
			config_data.network_id = this.dialog.bindings.data.network_id

		for (let [name, $input] of Object.entries(this.inputs()))
			config_data[name] = ($input)();

		this.dialog.close(config_data);
	}
}

class DataInputDialog
{
	constructor (bindings)
	{
		this.viewModel = new DataInputVM(this);
		this.fields = [];
		this.bindings = bindings;
		this.name = 'DataInput';
		this.object_to_display = bindings;
	}

	init ()
	{
		let fields = this.bindings.fields;

		if (this.bindings.config_data)
			this.viewModel.initialValue(this.bindings.config_data);

		if (this.bindings.title)
			this.viewModel.title(this.bindings.title);

		if (this.bindings.message)
			this.viewModel.message(this.bindings.message);

		if (this.bindings.width)
			this.viewModel.dialog_width(this.bindings.width);

		if (this.bindings.button_save_text)
			this.viewModel.btn_text(this.bindings.button_save_text);

		if (this.bindings.header_icon)
			this.viewModel.header_icon('fa ' + this.bindings.header_icon);
		else
			this.viewModel.header_icon('fa-regular fa-align-left');

		let data = this.bindings.data || {};

		fields.forEach((f) => {
			if (f.default_value == '' && data[f.name])
				f.default_value = data[f.name];
		});
		
		this.viewModel.fields(fields);
	}
}

export default {
	name: 'DataInputDialog',
	dialog_class: DataInputDialog,
	template: template,
	provider: "ps"
}

import template from './info.html';
import UserSettings from '../../../lib/UserSettings.js';

class ProfileInfoViewModel 
{
	constructor (page)
	{
		this.page = page;

		this.username = ko.observable();
		this.user_settings = ko.observable();
		this.user_setting_list = ko.pureComputed(() => {
			if (this.user_settings())
				return this.user_settings().get_sorted_array();
			else
				return [];
		});

		this.settings_edit = ko.observable(false);
	}

	/**
	 * Load data
	 */
	async load ()
	{
		let result = await fetch('/api/user/-1');
		let data = await result.json();
		
		this.username(data.user.username);
		this.user_settings(Grape.userSetting);
		this.user_settings().set_settings(await Grape.cache.fetch('UserSettings'));
		this.user_settings().set_values(JSON.parse(JSON.stringify(data.user.settings)));
		this.user_settings.notifySubscribers();
	}

	async btnSave_click ()
	{
		const obj = {
			username: this.username()
		};

		// Load setting values from inputs into obj
		for (let [k, v] of this.user_settings().entries())
			if (v.tags.indexOf('user_save') > -1 && v.input() != v.value)
				obj[k] = v.input();

		try {
			let response = await fetch('/api/user', 
			{
				method: 'POST',
				body: JSON.stringify(obj),
				headers: {'content-type': 'application/json'}
			});
			if (response.status == 200)
				Grape.alerts.alert({type: 'success', title: 'Settings Saved', message: 'Your settings have been saved'});
		} catch (err) {
			console.error(err);
			Grape.alert_api_error(err);
		}

		await this.load();
	}
}

/**
 * @constructor
 * @name ProfilePage
 */
class ProfileInfoPage 
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ProfileInfoViewModel(this);
		this.name = 'ProfilePage';
	}
	
	init ()
	{
		document.title = 'User Profile';
	}

	async updateData ()
	{
		try {
			await this.viewModel.load();
		} catch (err) { 
			console.error(err);
			Grape.alert_api_error(err);
		}
	}
}

export default {
	route: '[/profile/]info',
	page_id: 'info',
	page_class: ProfileInfoPage,
	template: template
};
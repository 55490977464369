// COMPONENTS
import fields_table_list from './custom-fields/fields-table-list.js';
import field_list from './custom-fields/field-list.js';
import map_definition_list from './json_mapper/map-definition-list.js';
import map_field_list from './json_mapper/map-field-list.js';
import lookup_list from './lookup/lookup-list.js';
import lookup_value_list from './lookup/lookup-value-list.js';

export default [
	fields_table_list,
	field_list,
	map_definition_list,
	map_field_list,
	lookup_list,
	lookup_value_list
];


import template from './AddEditMapField.html';
import MapFieldModel from '../../lib/models/json_mapper/JsonMapFieldModel.js'

class AddEditMapFieldVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.edit = this.dialog.bindings.edit;
		this.mapField = ko.observable();
		this.selectedMapDefinition = ko.observable();
		this.selectedMapDefinition.subscribe((newValue)=>{
			this.mapField().namespace(newValue?.namespace);
			this.mapField().name(newValue?.name);
		});
		window.mapField = this.mapField;
		this.transformTypes = ko.observableArray([]);

		Promise.all([
			this.fetchMapDefinitions(this.dialog.bindings?.mapField?.namespace, this.dialog.bindings?.mapField?.name),
			this.fetchTransformTypes()
		])
		.then(() => {
			this.mapField(new MapFieldModel(this.dialog.bindings.mapField));
		});

		this.mapDefinitions = ko.observableArray([]);

		this.mapDefinitionOptions = ko.computed(() => {
			return this.mapDefinitions().map((mapDefinition)=>{
				return {value:mapDefinition.map_definition_id, name:mapDefinition.name};
			});
		});

		this.mapDefinitionOptions = ko.computed(() => {
			if (!this.mapField())
				return [];
			return this.mapDefinitions()[this.mapField().map_definition_id()];
		});
	}

	close()
	{
		this.dialog.close();
	}

	async save()
	{
		let res;
		try {
			res = await this.mapField().save();
		} catch(err) {
			Grape.alerts.apiError(err);
		}

		if (res)
		{
			if (res.status !== 'OK')
			{
				Grape.alerts.apiError(res);
				return;
			}

			Grape.alerts.alert({type:'success', title:'Success', message:'Successfully Saved.'});
			this.dialog.close(res);
		}
	}

	async fetchMapDefinitions(namespace, name)
	{
		let filter = [];
		if (namespace != null && name != null)
			filter.push({field:'namespace', operator: '=', value: namespace}, {field:'name', operator: '=', value: name});

		let mapDefinitions = await Grape.tables.select({
			schema: 'json_mapper',
			table: 'map_definition',
			filter: filter
		});
		if (!mapDefinitions?.records)
			return;

		this.mapDefinitions(mapDefinitions?.records || []);
	}

	async fetchTransformTypes()
	{
		let transformTypes = await Grape.tables.select({
			schema: 'grape',
			table: 'v_plugin_consumer',
			filter: [{field: 'plugin_hook', operator: '=', value: 'json_mapper.transform_value'}]
		});
		if (!transformTypes?.records)
			return;

		this.transformTypes(transformTypes?.records || []);
	}
}

class AddEditMapFieldDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings || {};
		this.viewModel = new AddEditMapFieldVM(this); // Name of the ViewModel defined above
		this.name = 'AddEditMapField'; // Unique dialog name
	}
}

export default {
	name: 'AddEditMapField',
	dialog_class: AddEditMapFieldDialog,
	provider: 'ps',
	template: template
}


import template from './map-field-list.html';

/**
 * This is an example knockout component class. Note the export section at the end of the file.
 * @kind component
 * @class SampleComponent
 * @description Sample Component that shows the name value
 */
class MapFieldListViewModel
{
	constructor(params)
	{
		this.params = params;

		this.data = ko_helper.safe_observable(params.data);
		this.mapDefinition = ko_helper.safe_observable(params.mapDefinition);
		this.selectedMapField = ko_helper.safe_observable(params.selectedMapField);
		this.fieldListOptions = ko_helper.safe_observable(params.fieldListOptions || {});
		this.fieldListOptions.subscribe((newValue)=>{
			this.init();
		});

		this.records = ko.observableArray([]);

		this.map_fields = ko.observableArray([
			{
				name: 'field_name'
			},
			{
				name: 'idx'
			},
			{
				name: 'source_selector'
			},
			{
				name: 'target_path'
			},
			{
				name: 'transform_type'
			},
			{
				name: 'transform_options',
				formatter: 'json'
			}
		]);

		this.fields = ko.observableArray();
		this.visible_fields = ko.observableArray([]);
		this.visible_fields.subscribe(() => { this.update(); });

		this.filters = ko.observableArray([]);
		this.filters.subscribe(() => this.update());

		this.sortfield = ko.observable();
		this.sortorder = ko.observable('DESC');

		this.page_number = ko.observable();
		this.total_pages = ko.observable();

		this.init();
	}

	async init(){
		let options = {};
		if (window.localStorage.getItem('json-mapper.json-map-fields.map-field-list-options'))
			options = JSON.parse(window.localStorage.getItem('json-mapper.json-map-fields.map-field-list-options'));
		else
			options = {fields: ['map_field_id', 'map_definition_id', 'namespace', 'name', 'field_name', 'idx', 'source_selector', 'target_path', 'transform_type', 'transform_options']};

		options = Object.assign(options, this.fieldListOptions());

		const list = [];
		for (let field of this.map_fields())
		{
			if (!field.name)
				continue;
			const newfield = {
				name: field.name,
				visible: ko.observable(options.fields.indexOf(field.name) < 0 ? false : true)
			};

			if (field.formatter){
				if (field.formatter == 'json')
					newfield.formatter = x => JSON.stringify(x);
			}

			list.push(newfield);
		}
		this.fields(list);

		if (options.sortfield)
			this.sortfield(options.sortfield);
		if (options.sortorder)
			this.sortorder(options.sortorder);
		if (options.filter)
			this.filters(options.filter);
	}

	async update() {
		if (!this.mapDefinition()?.map_definition_id)
			return;
		this.fetchFields();
	}

	async fetchFields(){
		const options = this.serializeOptions();
		const result = await Grape.fetches.getJSON('/api/record', options);

		if (result.status == 'OK')
		{
			this.records(result.records);
			this.page_number(result.page_number);
			this.total_pages(result.total_pages);
		}
		else
			Grape.alerts.alert({type: 'error', message: result.message || 'Fail' });
	}

	serializeOptions()
	{
		const fields = ['map_field_id', 'map_definition_id', 'namespace', 'name', 'field_name', 'idx', 'source_selector', 'target_path', 'transform_type', 'transform_options'];

		for (let f of this.fields())
			if (f.visible())
				fields.push(f.name);

		const filters = [];

		for (let f of Object.assign(this.filters(), this.fieldListOptions().filter||[]))
			filters.push({field: f.field, operator: f.operator, value: f.value});

		const options = {
			schema: 'json_mapper',
			table: 'v_json_map_fields',
			fields: fields,
			filter: filters
		};

		if (this.sortfield() && this.sortfield().length)
		{
			options.sortfield = this.sortfield();
			options.sortorder = this.sortorder();
		}

		window.localStorage.setItem('json-mapper.json-map-fields.map-field-list-options', JSON.stringify(options));

		return options;
	}

	async editMapField(data, event){
		let res = await Grape.dialog.open('AddEditMapField', {mapField:data, edit: true});

		if (res?.status === 'OK'){
			this.update();
			this.selectedMapField('');
		}

	}

	async createMapField(data){
		let res = await Grape.dialog.open('AddEditMapField', {mapField: data||{namespace:this.mapDefinition()?.namespace, name:this.mapDefinition()?.name}});
		if (res?.status === 'OK'){
			this.update();
		}
	}

	async deleteMapField(data){
		let confirm = await Grape.alerts.confirm({type:'warning', title:'Delete', message:`Delete Map Field <b>"${data.field_name}"</b>? This will affect any dependencies.`, cancel_type:'default', accept_type:'warning'});

		if (confirm){
			let res = await Grape.fetches.deleteJSON('/api/json-map/field', {map_field_id:data.map_field_id});
			if (res?.status === 'OK'){
				this.update();
				return res;
			}

			Grape.alerts.apiError(res);
		}
	}
}

export default {
	name: 'map-field-list',
	viewModel: MapFieldListViewModel,
	module_type: 'ko',
	template: template
};

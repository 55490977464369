// PAGES
import ServerDashboard from './server-dashboard.js';
import UsersPage from './users/users.js';
import AccessRolesPage from './access_roles/access_roles.js';
import NetworksPage from './networks/networks.js';
import SessionsPage from './sessions/sessions.js';
import DashboardPages from './dashboard_pages/index.js';

//import CustomFieldsPage from './custom-fields/fields-tables.js';

import Profile from './profile-pages/profile.js';
import Profile_overview from './profile-pages/overview/overview.js';
import Profile_info from './profile-pages/info/info.js';
import Profile_advanced_settings from './profile-pages/advanced/advancedSettings.js';


export default [
	ServerDashboard,
	UsersPage,
	AccessRolesPage,
	NetworksPage,
	SessionsPage,
	Profile,
	Profile_overview,
	Profile_info,
	Profile_advanced_settings,
	DashboardPages
//	CustomFieldsPage
];

import template from './lookup-value-list.html';

class LookupValueListViewModel
{
	constructor(params)
	{
		this.params = params;

		this.data = ko_helper.safe_observable(params.data);
		this.lookup = ko_helper.safe_observable(params.lookup);
		this.selectedLookupValue = ko_helper.safe_observable(params.selectedLookupValue);
		this.lookupValueListOptions = ko_helper.safe_observable(params.lookupValueListOptions || {});
		this.lookupValueListOptions.subscribe((newValue)=>{
			this.init();
		});
		this.records = ko.observableArray([]);

		this.table_fields = ko.observableArray([
			{
				name: 'lookup_name'
			},
			{
				name: 'name'
			},
			{
				name: 'description'
			},
            {
                name: 'idx'
            },
			{
				name: 'data',
				formatter: 'json'
			}
		]);

		this.fields = ko.observableArray();
		this.visible_fields = ko.observableArray([]);
		this.visible_fields.subscribe(() => { this.update(); });

		this.filters = ko.observableArray([]);
		this.filters.subscribe(() => this.update());

		this.sortfield = ko.observable();
		this.sortorder = ko.observable('DESC');

		this.page_number = ko.observable();
		this.total_pages = ko.observable();

		this.init();
	}

	async init()
	{
		let options = {};
		if (window.localStorage.getItem('lookup.value-list-options'))
			options = JSON.parse(window.localStorage.getItem('lookup.value-list-options'));
		else
			options = {fields: ['lookup_value_id', 'lookup_id','lookup_name','name','description','idx','data']};

		options = Object.assign(options, this.lookupValueListOptions());

		const list = [];
		for (let field of this.table_fields())
		{
			if (!field.name)
				continue;
			const newfield = {
				name: field.name,
				visible: ko.observable(options.fields.indexOf(field.name) < 0 ? false : true)
			};

			if (field.formatter){
				if (field.formatter == 'json')
					newfield.formatter = x => JSON.stringify(x);
			}

			list.push(newfield);
		}
		this.fields(list);

		if (options.sortfield)
			this.sortfield(options.sortfield);
		if (options.sortorder)
			this.sortorder(options.sortorder);
		if (options.filter)
			this.filters(options.filter);
	}

	async update() 
	{
		this.fetchLookupValue();
	}

	async fetchLookupValue()
	{
		const options = this.serializeOptions();
		const result = await Grape.fetches.getJSON('/api/record', options);

		if (result.status == 'OK')
		{
			this.records(result.records);
			this.page_number(result.page_number);
			this.total_pages(result.total_pages);
		}
		else
			Grape.alerts.alert({type: 'error', message: result.message || 'Fail' });
	}

	serializeOptions()
	{
		const fields = ['lookup_value_id', 'lookup_id','lookup_name','name','description','idx','data'];

		for (let f of this.fields())
			if (f.visible())
				fields.push(f.name);

		const filters = [];

		for (let f of Object.assign(this.filters(), this.lookupValueListOptions().filter||[]))
			filters.push({field: f.field, operator: f.operator, value: f.value});

		const options = {
			schema: 'grape',
			table: 'v_lookup_value',
			fields: fields,
			filter: filters
		};

		if (this.sortfield() && this.sortfield().length)
		{
			options.sortfield = this.sortfield();
			options.sortorder = this.sortorder();
		}

		window.localStorage.setItem('lookup.value-list-options', JSON.stringify(options));

		return options;
	}

	async editLookupValue(data, event)
	{
		let res = await Grape.dialog.open('AddEditLookupValue', {lookup_value:data, edit: true});

		if (res?.status === 'OK'){
			this.update();
			this.selectedLookupValue('');
		}
	}

	async upsertTableLookupValue()
	{
		let lookup_name = this.lookup()?.name || '';

		let res = await Grape.dialog.open('AddEditLookupValue', {
			lookup_value:{
				lookup_name
			}
		});
		if (res?.status === 'OK'){
			this.update();
		}
	}

	async deleteLookupValue(data)
	{
		let confirm = await Grape.alerts.confirm({type:'warning', title:'Delete', message:`Delete Lookup Value <b>(${data.name})</b>?`, cancel_type:'default', accept_type:'warning'});

		if (confirm){
			let res = await Grape.fetches.postJSON('/api/lookup/value/delete', {lookup_value_id:data.lookup_value_id});
			if (res?.status === 'OK'){
				this.update();
				return res;
			}

			Grape.alerts.apiError(res);
		}
	}
}

export default {
	name: 'lookup-value-list',
	viewModel: LookupValueListViewModel,
	module_type: 'ko',
	template: template
};

import template from './lookup-list.html';

class LookupListViewModel
{
	constructor(params)
	{
		this.params = params;
		this.data = ko_helper.safe_observable(params.data);
		this.selectedLookup = ko_helper.safe_observable(params.selectedLookup);
		this.selected = ko.observable();
		this.records = ko.observableArray([]);

		this.table_fields = ko.observableArray([
			{
				name: 'name'
			},
			{
				name: 'description'
			},
			{
				name: 'field_definition',
                formatter: 'json'
			},
			{
				name: 'options',
				formatter: 'json'
			}
		]);

		this.fields = ko.observableArray();
		this.visible_fields = ko.observableArray([]);
		this.visible_fields.subscribe(() => { this.update(); });

		this.filters = ko.observableArray([]);
		this.filters.subscribe(() => this.update());

		this.sortfield = ko.observable();
		this.sortorder = ko.observable('DESC');

		this.page_number = ko.observable();
		this.total_pages = ko.observable();

		this.init();
	}

	async init()
	{
		let options = {};
		if (window.localStorage.getItem('lookup.lookup-list-options'))
			options = JSON.parse(window.localStorage.getItem('lookup.lookup-list-options'));
		else
			options = {
				fields: ['lookup_id','name','description','field_definition','options']
			};

			let list = [];
			for (let field of this.table_fields())
			{
				if (!field.name)
					continue;
				let newfield = {
					name: field.name,
					visible: ko.observable(options.fields.indexOf(field.name) < 0 ? false : true)
				};

				if (field.formatter)
				{
					if (field.formatter == 'json')
						newfield.formatter = (x) => JSON.stringify(x);
				}

				list.push(newfield);
			}
			this.fields(list);

			if (options.sortfield)
				this.sortfield(options.sortfield);
			if (options.sortorder)
				this.sortorder(options.sortorder);
			if (options.filter)
				this.filters(options.filter);
	}

	async update()
	{
		this.fetchLookup();
	}

	async fetchLookup()
	{
		let options = this.serializeOptions();
		let result = await Grape.fetches.getJSON('/api/record', options);
		if (result.status == 'OK')
		{
			this.records(result.records);
			this.page_number(result.page_number);
			this.total_pages(result.total_pages);
		}
		else
			Grape.alerts.alert({type: 'error', message: result.message || 'Fail' });
	}

	serializeOptions()
	{
		let fields = ['lookup_id','name','description','field_definition','options'];
		for (let f of this.fields())
			if (f.visible())
				fields.push(f.name);

		let filters = [];
		for (let f of this.filters())
			filters.push({field: f.field, operator: f.operator, value: f.value});

		let options = {
			schema: 'grape',
			table: 'lookup',
			fields: fields,
			filter: filters
		};

		if (this.sortfield() && this.sortfield().length)
		{
			options.sortfield = this.sortfield();
			options.sortorder = this.sortorder();
		}

		window.localStorage.setItem('lookup.lookup-list-options', JSON.stringify(options));

		return options;
	}

	async editLookup(data)
	{
		let res = await Grape.dialog.open('AddEditLookup', {lookup:data, edit: true});

		if (res?.status === 'OK')
		{
			this.update();
			this.selectLookup('');
		}
	}

	selectLookup(data, event)
	{
		if (data === this.selected()) 
			this.selected(null);
		else 
			this.selected(data);

		this.selectedLookup(data);
	}

	async btn_upsert_lookup()
	{
		let res = await Grape.dialog.open('AddEditLookup', {lookup:{}});
		if (res?.status === 'OK')
			this.update();
	}

	async deleteLookup(data)
	{
		let confirm = await Grape.alerts.confirm({type:'warning', title:'Delete', message:`Delete Lookup <b>${data.name}</b>?`, cancel_type:'default', accept_type:'warning'});
		if (confirm)
		{
			let res = await Grape.fetches.postJSON('/api/lookup/delete', {lookup_id: data.lookup_id});
			if (res?.status === 'OK')
			{
				this.update();
				this.selectedLookup('');
				return res;
			}

			Grape.alerts.apiError(res);
		}
	}
}

export default {
	name: 'lookup-list',
	viewModel: LookupListViewModel,
	module_type: 'ko',
	template: template
};
import template from './advancedSettings.html';

class ProfileAdvancedSettingsPageVM
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;

		this.UserProfileDialogs = ko.observableArray(window.UserAccountDialogs || []);
		this.UserProfilePages = ko.observableArray(window.UserAccountPages || []);

		this.uuid = ko.observable(this.page.bindings.data?.uuid ? this.page.bindings.data.uuid : null);
		this.otp_methods = ko.observableArray(this.page.bindings.data.auth_info.otp_methods);
		this.delegatee_username = ko.observable();
		this.delegator_username = ko.observable();
		this.delegation_code = ko.observable();
		this.delegation_expiry_date = ko.observable();
	}
	
	async btnUserProfileDialog_click (item)
	{
		await Grape.dialog.open(item.dialog_name, {});
	}

	btnUserProfilePage_click (item)
	{
		window.Grape.navigate(item.navigate_url);
	}

	btnManageAPICredentials_click ()
	{
		window.Grape.navigate('/grape-users/api-creds');
	}

	async btnGrantUserAccess_click ()
	{
		try {
			let result = await Grape.fetches.postJSON('api/user/delegate', { delegatee: this.delegatee_username(), expiry_date: this.delegation_expiry_date() });
			
			if (result.status != 'ERROR')
				Grape.alerts.alert({ 
					type: 'success', 
					title: `Granted access to ${this.delegatee_username()}!`, 
					message: ` Save the following access code to login to ${this.delegatee_username()}: \n${result.access_code}`});
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async btnLoginAsDelegatee_click ()
	{
		try {
			let result = await Grape.fetches.postJSON('/api/session/switch-user', { delegation_code: this.delegation_code(), username: this.delegator_username() });
			
			if (result.status != 'ERROR')
				window.location.reload();
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}
}

class ProfileAdvancedSettingsPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ProfileAdvancedSettingsPageVM(this);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Advanced Settings';
	}

	async update_data ()
	{
		try 
		{
			let result = await fetch('/api/user/-1');
			let data = await result.json();

			if (result.ok)
			{
				this.viewModel.uuid(data.user.uuid);
				this.viewModel.otp_methods(data.user.auth_info.otp_methods);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}	
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/profile/]advanced-settings',
	page_id: 'overview',
	page_class: ProfileAdvancedSettingsPage,
	template: template
};

import template from './MapDefinitionTest.html';
import MapDefinitionModel from './../../lib/models/json_mapper/JsonMapDefinitionModel.js'

class MapDefinitionTestVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.mapDefinition = ko.observable(new MapDefinitionModel(this.dialog.bindings.mapDefinition));
		this.source = ko.observable('{}');
		this.template = ko.observable('');
		this.target = ko.observable();
	}

	close()
	{
		this.dialog.close();
	}

	async mapObject()
	{
		let source = JSON.parse(this.source());
		let template = JSON.parse(this.template()|| '""');
		let res = await this.mapDefinition().mapObject(source, template);
		this.target(JSON.stringify(res.target, null, '  '));
	}
}

class MapDefinitionTestDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings || {};
		this.viewModel = new MapDefinitionTestVM(this); // Name of the ViewModel defined above
		this.name = 'MapDefinitionTest'; // Unique dialog name
	}
}

export default {
	name: 'MapDefinitionTest',
	dialog_class: MapDefinitionTestDialog,
	provider: 'ps',
	template: template
}

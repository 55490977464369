
import template from './map-definition-list.html';

/**
 * This is an example knockout component class. Note the export section at the end of the file.
 * @kind component
 * @class SampleComponent
 * @description Sample Component that shows the name value
 */
class MapDefinitionListViewModel
{
	constructor(params)
	{
		this.params = params;

		this.data = ko_helper.safe_observable(params.data);
		this.selectedMapDefinition = ko_helper.safe_observable(params.selectedMapDefinition);

		this.records = ko.observableArray([]);

		this.map_definitions = ko.observableArray([
			{
				name: 'namespace'
			},
			{
				name: 'name'
			},
			{
				name: 'description'
			},
			{
				name: 'base_object',
				formatter: 'json'
			}
		]);

		this.fields = ko.observableArray();
		this.visible_fields = ko.observableArray([]);
		this.visible_fields.subscribe(() => { this.update(); });

		this.filters = ko.observableArray([]);
		this.filters.subscribe(() => this.update());

		this.sortfield = ko.observable();
		this.sortorder = ko.observable('DESC');

		this.page_number = ko.observable();
		this.total_pages = ko.observable();

		this.init();
	}

	async init()
	{
		let options = {};
		if (window.localStorage.getItem('json-mapper.map-definition.map-definition-list-options'))
			options = JSON.parse(window.localStorage.getItem('json-mapper.map-definition.map-definition-list-options'));
		else
			options = {
				fields: ['map_definition_id', 'namespace', 'name', 'description', 'base_object']
			};

			let list = [];
			for (let field of this.map_definitions())
			{
				if (!field.name)
					continue;
				let newfield = {
					name: field.name,
					visible: ko.observable(options.fields.indexOf(field.name) < 0 ? false : true)
				};

				if (field.formatter)
				{
					if (field.formatter == 'json')
						newfield.formatter = x => JSON.stringify(x);
				}

				list.push(newfield);
			}
			this.fields(list);

			if (options.sortfield)
				this.sortfield(options.sortfield);
			if (options.sortorder)
				this.sortorder(options.sortorder);
			if (options.filter)
				this.filters(options.filter);
	}

	async update()
	{
		this.selectedMapDefinition({});
		this.fetchMapDefinitions();
	}

	async fetchMapDefinitions()
	{
		let options = this.serializeOptions();
		let result = await Grape.fetches.getJSON('/api/record', options);
		if (result.status == 'OK')
		{
			this.records(result.records);
			this.page_number(result.page_number);
			this.total_pages(result.total_pages);
		}
		else
			Grape.alerts.alert({type: 'error', message: result.message || 'Fail' });
	}

	serializeOptions()
	{
		let fields = ['map_definition_id', 'namespace', 'name', 'description', 'base_object'];
		for (let f of this.fields())
			if (f.visible())
				fields.push(f.name);

		let filters = [];
		for (let f of this.filters())
			filters.push({field: f.field, operator: f.operator, value: f.value});

		let options = {
			schema: 'json_mapper',
			table: 'map_definition',
			fields: fields,
			filter: filters
		};

		if (this.sortfield() && this.sortfield().length)
		{
			options.sortfield = this.sortfield();
			options.sortorder = this.sortorder();
		}

		window.localStorage.setItem('json-mapper.map-definition.map-definition-list-options', JSON.stringify(options));

		return options;
	}

	async editMapDefinition(data, event)
	{
		let res = await Grape.dialog.open('AddEditMapDefinition', {mapDefinition:data, edit: true});

		if (res?.status === 'OK')
		{
			this.update();
			this.selectMapDefinition('');
		}
	}

	selectMapDefinition(data, event)
	{
		this.selectedMapDefinition(data);
	}

	async createMapDefinition()
	{
		let res = await Grape.dialog.open('AddEditMapDefinition', {mapDefinition:{}});
		if (res?.status === 'OK')
			this.update();
	}

	async deleteMapDefinition(data)
	{
		let confirm = await Grape.alerts.confirm({type:'warning', title:'Delete', message:`Delete Map Definition <b>"${data.name}"</b>? This will affect any dependencies.`, cancel_type:'default', accept_type:'warning'});
		if (confirm)
		{
			let res = await Grape.fetches.deleteJSON('/api/json-map/definition', {map_definition_id:data.map_definition_id});
			if (res?.status === 'OK')
			{
				this.update();
				this.selectedMapDefinition('');
				return res;
			}

			Grape.alerts.apiError(res);
		}
	}

	async testMapDefinition(data)
	{
		Grape.dialog.open('MapDefinitionTest', {mapDefinition:data});
	}
}

export default {
	name: 'map-definition-list',
	viewModel: MapDefinitionListViewModel,
	module_type: 'ko',
	template: template
};

import template from './profile.html';

class ProfileViewModel
{
	constructor(page)
	{
		this.page = page;
		this.tabs = ko.observableArray([
			{
				route: '[/profile/]overview',
				title: 'Overview',
				icon: ''
			},
			{
				route: '[/profile/]info',
				title: 'Info',
				icon: ''
			},
			{
				route: '[/profile/]advanced-settings',
				title: 'Advanced Settings',
				icon: ''
			}
		]); 
		this.UserProfileDialogs = ko.observableArray(window.UserProfileDialogs || []);
		this.UserProfilePages = ko.observableArray(window.UserProfilePages || []);
		this.data = ko.observable();
		this.current_route = ko.observable();
		this.profile_photo = ko.observable();
		this.upload_photo = ko.observable();
		this.username = ko.observable();
	}

	load_tab (route)
	{
		window.localStorage.setItem('last_account_page', route);
		const element = this.page.element.querySelector('#page_content');
		this.current_route(route);
		const page = Grape.pages.find_page_by_id(route);

		if (!page)
			throw new Error(`Page with id "${route}" not found`);

		Grape.pages.load_page(page, element, { data: this.data() }, {}, () => {});
	}

	async upload_profile_photo () 
	{
		document.getElementById('image_upload').click();
	}
}

class ProfileViewPage
{
	constructor(bindings, element)
	{
		this.viewModel = new ProfileViewModel(this);
		this.bindings = bindings;
		this.element = element;
	}
	
	async init ()
	{
		document.title = 'Stock Item';
		await this.load();
		await this.loadProfilePhoto();

		if (window.localStorage.getItem('last_account_page'))
			this.viewModel.load_tab(window.localStorage.getItem('last_account_page'));
		else
			this.viewModel.load_tab('[/profile/]overview');

		// Register file upload event listener
		const hiddenFileInput = document.getElementById('image_upload');
		hiddenFileInput.addEventListener('change', async () => {
			const selectedFile = hiddenFileInput.files[0]; // Get the selected file

			if (selectedFile)
			{
				let header_info = { 
					"X-SessionID": localStorage.getItem('session_id'),
					"Accept": "application/json"
				};

				//SERVER: Upload file(s)
				try
				{
					let response = await fetch('/api/user/photo', {
						method: 'POST',
						body: new FormData(document.getElementById('image_upload_form')),
						headers: header_info
					});

					let data = await response.json();
					if (response.ok)
						Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Profile Photo Successfully Uploaded'});
					else 
						throw new Error(data.message);
				} catch (exception) {
					Grape.alerts.alert({ type:'warning', message: exception, title:`File Upload Error` });
					console.error(exception);
				} finally {
					this.update_data();
				}
			}
		});
	}	
	
	async load ()
	{
		try {
			let result = await fetch('/api/user/-1');
			let data = await result.json();

			if (result.ok)
			{
				this.viewModel.data(data.user);
				this.viewModel.username(data.user.username)
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async loadProfilePhoto ()
	{
		try
		{
			let result = await fetch(`/api/user/photo`, {
				method: 'GET',
				headers: { 'Content-Type': 'image/png' }
			});

			if (result.status == 200)
			{
				let blob = await result.blob()
				let img = document.getElementById('pfp');
				img.src = URL.createObjectURL(blob);
				this.viewModel.profile_photo(URL.createObjectURL(blob));
			} else if (result.status == 204)
				console.log('No user profile photo found');
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}
	}

	async update_data ()
	{
		try {
			await this.load();
			await this.loadProfilePhoto();
		} catch (err) { 
			console.error(err);
			Grape.alert_api_error(err);
		}
	}
}

export default {
	route: '[/]grape-ui/my-profile',
	page_class: ProfileViewPage,
	template: template
}
import template from './lookups.html';

/**
 *  Lookups view model
 */
class LookupsViewModel {
	constructor(page) {
		this.page = page;
		this.element = page.element;

		this.lookupValueListOptions = ko.observable({filter:[{field:'lookup_name', operator:'=', value:''}]});
		this.selectedLookup = ko.observable();
		this.selectedLookup.subscribe((newValue)=>{
			this.lookupValueListOptions({filter:[
				{field:'lookup_name', operator:'=', value:newValue.name},
			]});
		});
		this.selectedLookupValue = ko.observable();
	}

}

/**
 * Lookups class
 */
class LookupsClass {
	constructor(bindings, element) {
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new LookupsViewModel(this);
		this.name = 'LookupsClass';
	}

	async init() {
	}

	async updateData() {
	}

	async teardown() {
	}
};

export default {
	route: '[/]grape-ui/lookups',
	page_id: '[/]grape-ui/lookups',
	page_class: LookupsClass,
	template: template,
	title: 'Lookups',
	icon: 'fa-regular fa-eye',
	idx: 10
}
import template from './AddEditLookupValue.html';
import LookupValueModel from '../../lib/models/lookup/LookupValueModel.js'

class AddEditLookupValueVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.edit = this.dialog.bindings.edit;
		this.lookupValue = ko.observable(new LookupValueModel(this.dialog.bindings.lookup_value));
	}

	close()
	{
		this.dialog.close();
	}

	async save()
	{
		let res = await this.lookupValue().save();
		if (res)
		{
			if (res.status !== 'OK')
			{
				Grape.alerts.apiError(res);
				return;
			}

			Grape.alerts.alert({type:'success', title:'Success', message:'Successfully Saved.'});
			this.dialog.close(res);
		}
	}
}

class AddEditLookupValueDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings || {};
		this.viewModel = new AddEditLookupValueVM(this); // Name of the ViewModel defined above
		this.name = 'AddEditLookup'; // Unique dialog name
	}
}

export default {
	name: 'AddEditLookupValue',
	dialog_class: AddEditLookupValueDialog,
	provider: 'ps',
	template: template
}
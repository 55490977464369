class LookupModel 
{
	constructor({lookup_id, name, description, field_definition, options})
	{
		this.lookup_id = ko_helper.safe_observable(lookup_id);
		this.name = ko_helper.safe_observable(name);
		this.description = ko_helper.safe_observable(description);
		this.field_definition = ko.observable();
		this.options = ko.observable();

		if (typeof options === 'object')
			this.options(JSON.stringify(options));
		else
			this.options(options);

        if (typeof field_definition === 'object')
            this.field_definition(JSON.stringify(field_definition));
        else
            this.field_definition(field_definition);
	}

	async save()
	{
		if (!this.name())
			Grape.alerts.alert({type: 'warning', message: 'Lookup Name cannot be empty!'});
		else
		{
			let payload = this.serialize();
			if (typeof payload.options === 'string')
			{
				try {
					payload.options = JSON.parse(payload.options);
				} catch (err) {
					return {status: 'ERROR', code: 'Invalid Data', message:'Options is not valid JSON'};
				}
			}

            if (typeof payload.field_definition === 'string')
            {
                try {
                    payload.field_definition = JSON.parse(payload.field_definition);
                } catch (err) {
                    return {status: 'ERROR', code: 'Invalid Data', message:'Field Definition is not valid JSON'};
                }
            }

			return Grape.fetches.postJSON('/api/lookup/save', payload);
		}
	}

	async delete()
	{
		return Grape.fetches.deleteJSON('/api/lookup/delete', {name: this.name(),lookup_id: this.lookup_id()});
	}

	serialize()
	{
		return {
			lookup_id: this.lookup_id(),
			name: this.name(),
			description: this.description(),
            field_definition: this.field_definition(),
			options: this.options()
		};
	}

}

export default LookupModel;
class LookupValueModel 
{
	constructor({lookup_value_id, lookup_name, name, description, idx, data})
	{
		this.lookup_value_id = ko_helper.safe_observable(lookup_value_id);
        this.lookup_name = ko_helper.safe_observable(lookup_name);
		this.name = ko_helper.safe_observable(name);
		this.description = ko_helper.safe_observable(description);
		this.idx = ko_helper.safe_observable(idx);
		this.data = ko.observable();

		if (typeof data === 'object')
			this.data(JSON.stringify(data));
		else
			this.data(data);
	}

	async save()
	{
		if (!this.name())
			Grape.alerts.alert({type: 'warning', message: 'Lookup Value name cannot be empty!'});
		else
		{
			let payload = this.serialize();
			if (typeof payload.data === 'string')
			{
				try {
					payload.data = JSON.parse(payload.data);
				} catch (err) {
					return {status: 'ERROR', code: 'Invalid Data', message:'Data is not valid JSON'};
				}
			}

			return Grape.fetches.postJSON('/api/lookup/value/save', payload);
		}
	}

	async delete()
	{
		return Grape.fetches.deleteJSON('/api/lookup/value/delete', {lookup_value_id: this.lookup_value_id()});
	}

	serialize()
	{
		return {
			lookup_value_id: this.lookup_value_id(),
            lookup_name: this.lookup_name(),
			name: this.name(),
			description: this.description(),
            idx: this.idx(),
			data: this.data()
		};
	}

}

export default LookupValueModel;
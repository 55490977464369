
import template from './AddEditFieldsTable.html';
import FieldsTableModel from './../../lib/models/FieldsTableModel.js'

class AddEditFieldsTableVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.edit = this.dialog.bindings.edit;
		this.fieldsTable = ko.observable(new FieldsTableModel(this.dialog.bindings.fieldsTable));
	}

	close()
	{
		this.dialog.close();
	}

	async save()
	{
		let res = await this.fieldsTable().save();
		if (res)
		{
			if (res?.status !== 'OK')
			{
				Grape.alerts.apiError(res);
				return;
			}

			Grape.alerts.alert({type:'success', title:'Success', message:'Successfully Saved.'});
			this.dialog.close(res);
		}
	}
}

class AddEditFieldsTableDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings || {};
		this.viewModel = new AddEditFieldsTableVM(this); // Name of the ViewModel defined above
		this.name = 'AddEditFieldsTable'; // Unique dialog name
	}
}

export default {
	name: 'AddEditFieldsTable',
	dialog_class: AddEditFieldsTableDialog,
	provider: 'ps',
	template: template
}

/**
 * This is an example of how to get the list of values inside the Grape lookup 
 * "SampleCustomFunctionLookup" (which gets created from initial.manifest and is defined in backend/db/data/sample_lookup.sql) 
 * registered into a GrapeUI cache called "SampleLookup".
 *
 * This values can be retrieved with the following code:
 * window.Grape.cache.fetch(function(data) {
 *     // DO SOMETHING WITH data
 *     // TODO: add example of what data would look like here.
 * });
 *
 */

export default {
	name: 'EventNames',
	options: {
		refresh: async () => {
			const result = await Grape.fetches.getJSON('/api/record', {
				schema: 'events',
				table: 'v_events',
				fields: ['namespace', 'object_type', 'name']
			});
			return result.records;
		}
	}
};


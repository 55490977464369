import template from './server-dashboard.html';

class ServerDashboardVM
{
	constructor (page)
	{
		this.page = page;
		this.title_visibility = ko.observable(true);
		const register = Grape.registry.getRegister('PSServerDashboardPages');
		const tabs = [];
		if (register)
			for (let [key, value] of register.entries())
				tabs.push(Object.assign({title: key, route: value.page_id || value.route }, value));
		console.log('tabs=',tabs);

		tabs.sort((a, b) => {
			if (a.idx === undefined) return 1;
			if (b.idx === undefined) return -1;
			if (a.idx === b.idx) return a.title.localeCompare(b.title);
			return a.idx - b.idx;
		});

		this.tabs = ko.observableArray(tabs); 
		this.current_route = ko.observable();
	}

	load_tab (route)
	{
		console.log(`Load Tab ${route}`);
		window.localStorage.setItem('server_dashboard.last_route', route);
		const element = this.page.element.querySelector('#page_content');
		let page = Grape.pages.find_page_by_id(route);
		if (!page)
			throw new Error(`Page with id "${route}" not found`);
		this.current_route(route);
		Grape.pages.load_page(page, element, {}, {}, () => {});
	}
}

class ServerDashboardPage 
{
	constructor (bindings, element, page)
	{
		this.viewModel = new ServerDashboardVM(this);
		this.bindings = bindings;
		this.element = element;
	}
	
	async init ()
	{
		document.title = 'Server Dashboard';

		if (window.localStorage.getItem('server_dashboard.last_route'))
			this.viewModel.load_tab(window.localStorage.getItem('server_dashboard.last_route'));
		else
			this.viewModel.load_tab('ps_dashboard.overview');
	}

	updateData (v,e)
	{
	}

	async teardown ()
	{
	}
}

export default {
	route: '[/]ui/server-dashboard',
	page_class: ServerDashboardPage,
	template: template
}


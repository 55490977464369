import JsonMapField from "./JsonMapFieldModel";

class JsonMapDefinition
{
	constructor(data)
	{
		this.map_definition_id = ko.observable();
		this.namespace = ko.observable();
		this.name = ko.observable();
		this.description = ko.observable();
		this.base_object = ko.observable('{}');
		this.map_fields = ko.observableArray();

		if (data)
			this.set(data);
	}

	async set(data) {
		if (data.hasOwnProperty('map_definition_id'))
			this.map_definition_id(data.map_definition_id);

		if (data.hasOwnProperty('namespace'))
			this.namespace(data.namespace);

		if (data.hasOwnProperty('name'))
			this.name(data.name);

		if (data.hasOwnProperty('description'))
			this.description(data.description);

		if (data.hasOwnProperty('base_object'))
			this.base_object(JSON.stringify(data.base_object));

		if (data.hasOwnProperty('map_fields')){
			let map_fields = [];
			for (let map_field of data.map_fields){
				map_fields.push(new JsonMapField(map_field));
			}
			this.map_fields(map_fields);
		}
	}

	async save()
	{

		let payload = this.serialize();

		return Grape.fetches.postJSON('/api/json-map/definition', payload);

	}

	async delete()
	{
		return Grape.fetches.deleteJSON('/api/json-map/definition', {map_definition_id:this.map_definition_id()});
	}

	async mapObject(source, template)
	{
		const payload = {namespace: this.namespace(), name: this.name(), source, template};
		return Grape.fetches.postJSON('/api/json-map/map-object', payload);
	}

	serialize()
	{
		let obj = {
			map_definition_id: this.map_definition_id(),
			namespace: this.namespace(),
			name: this.name(),
			description: this.description()
		};

		let base_object = (this.base_object()||'').trim();
		if (base_object)
			obj.base_object = JSON.parse(base_object);

		return obj;
	}
}

export default JsonMapDefinition;

import template from './subscriptions.html';

class SubscriptionsDashboardVM
{
	constructor(page)
	{
		this.page = page;
		this.element = page.element;
		this.subscriptions = ko.observableArray([]);
	}

	async btn_edit_subscription(subscription)
	{
		let result = await Grape.dialog.open('EditSubscriptionDialog', subscription ? { subscription: subscription } : {});
		if (result)
			this.page.updateData();
	}

	async btn_delete_subscription(data)
	{
		let result = await Grape.fetches.postJSON(`/api/subscription/delete`, { subscription_uuid: data.subscription_uuid });
		if (result)
			this.page.updateData();
	}
}

class SubscriptionsDashboardPage
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new SubscriptionsDashboardVM(this);
	}

	async init()
	{
		document.title = 'Dashboard - Events';
	}

	async updateData()
	{
		let result = await Grape.fetches.getJSON(`/api/record`, { schema: 'events', table: 'v_subscription' });
		if (result.status != 'ERROR')
			this.viewModel.subscriptions(result.records || []);
		else
			Grape.alerts.alert({ type: 'error', title: 'Error', message: 'An error ocurred while loading subscriptions' });
	}
}

export default {
	route: '[/]ui/messages/subscriptions',
	page_class: SubscriptionsDashboardPage,
	template: template,
	name: 'subscription-dashboard',
	title: 'Events',
	page_id: 'ps_dashboard.subscription',
	icon: 'fa-regular fa-link',
	idx: 4
};

import template from './json_mapper.html';

/**
 *  JsonMapper view model
 */
class JsonMapperViewModel {
	constructor(page) {
		this.page = page;
		this.element = page.element;

		this.lookupValueListOptions = ko.observable({filter:[{field:'name', operator:'=', value:''}]});
		this.selectedMapDefinition = ko.observable();
		this.selectedMapDefinition.subscribe((newValue)=>{
			this.lookupValueListOptions({filter:[
				{field:'namespace', operator:'=', value:newValue.namespace},
				{field:'name', operator:'=', value:newValue.name}
			]});
		});
		this.selectedLookupValue = ko.observable();

	}

}

/**
 * JsonMapper class
 */
class JsonMapperClass {
	constructor(bindings, element) {
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new JsonMapperViewModel(this);
		this.name = 'JsonMapperClass';

	}

	async init() {
	}

	async updateData() {
	}

	async teardown() {
	}
};

export default {
	route: '[/]ui/setup/json-mapper',
	page_id: '[/]ui/setup/json-mapper',
	page_class: JsonMapperClass,
	template: template,
	title: 'JSON Mapper',
	icon: 'fa-regular fa-shuffle',
	idx: 10
}

import template from './users_menu.html'

class UsersMenuPageVM
{
	constructor (page)
	{
		this.page = page;

		this.tabs = ko.observableArray([
			{
				title: 'Users',
				route: '[/]ui/users',
				icon: 'fa-regular fa-users'
			},
			{
				title: 'Access Roles',
				route: '[/]ui/access_roles',
				icon: 'fa-regular fa-lock-a'
			},
			{
				title: 'Networks',
				route: '[/]ui/networks',
				icon: 'fa-regular fa-code-fork'
			},
			{
				title: 'Sessions',
				route: '[/]ui/sessions',
				icon: 'fa-regular fa-info-circle'
			}
		]);
		this.current_route = ko.observable('[/]ui/users');
	}

	load_tab (route)
	{
		window.localStorage.setItem('users_menu.last_route', route);
		const element = document.querySelector('.users_page_content');
		this.current_route(route);
		const page = Grape.pages.find_page_by_id(route);
		if (!page)
			throw new Error(`Page with id "${route}" not found`);
		Grape.pages.load_page(page, element, {}, {}, () => {});
	}
}

class UsersMenuPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new UsersMenuPageVM(this);
	}

	init ()
	{
		document.title = 'Dashboard - Users Menu';
		
		if (window.localStorage.getItem('users_menu.last_route'))
			this.viewModel.load_tab(window.localStorage.getItem('users_menu.last_route'));
		else
			this.viewModel.load_tab('[/]ui/users');
	}
}

export default {
	route: '[/]ui/users/menu',
	page_class: UsersMenuPage,
	template: template,
	name: 'users_menu',
	title: 'Users',
	page_id: 'ps_dashboard.users_menu',
	icon: 'fa-regular fa-users',
	idx: 1
}